import React from "react";
import Router from "./Router";
import GlobalStyles from "GlobalStyles";

function App() {
  return (
    <>
      <Router />
      <GlobalStyles />
    </>
  );
}

export default App;
